.sa-header {
  $self: &;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  // pointer-events: none;
  transition: color 0.2s ease-out;
  padding-bottom: 10px;

  &--shadow {
    box-shadow: 0 0 15px 1px hsla(0, 0%, 50%, 0.1);
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 190px;
    background: none;
    content: "";
    pointer-events: none;
  }

  &.sa-header--closer {
    &:before {
      height: 190px;
      background: linear-gradient(
        180deg,
        $white 0%,
        $white 75%,
        rgba(43, 38, 110, 0) 100%
      );
    }
  }

  @include respond-above(md) {
    .dropdown-menu-xl {
      min-width: 900px !important;
      border-radius: 0.375rem;
      margin: 0;
      left: 50%;
      transform: translateX(-50%) translateY(15px) scale(1);
      margin-left: 100px;
    }
  }

  &__top {
    padding: 0.5rem 1rem;
    position: relative;

    @include respond-below(md) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include respond-above(sm) {
      padding-top: 20px;
    }

    &__inner {
      position: relative;
      padding-bottom: 0.5rem;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        pointer-events: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      }
    }

    .sa-social {
      a {
        &:hover {
          .sa-icon {
            path {
              fill: $secondary-color;
            }
          }
        }
      }
    }
  }

  &__sa-logo {
    pointer-events: auto;
    display: flex;
    align-items: center;
    flex-grow: 1;

    a {
      width: 107px;

      @include respond-above(lg) {
        width: 180px;
      }
    }

    &__light {
      display: none;
    }
  }

  &__logo {
    width: 107px;
    margin-right: 30px;
    pointer-events: auto;

    @include respond-above(lg) {
      width: 180px;
    }

    &__light {
      display: none;
    }
  }

  a {
    color: $primary-color;

    &:hover {
      color: $secondary-color;
    }
  }

  .btn {
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: none;

    &:hover {
      color: $primary-color;
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  .navbar-expand-lg {
    @include respond-below(md) {
      .dropdown,
      .dropleft,
      .dropright,
      .dropup {
        display: inline-block;
      }

      .navbar-collapse .dropdown-menu {
        border-radius: 0;
      }
      .navbar-collapse-overlay {
        // background-color: $primary-color;
        position: fixed;
        top: 140px;
        right: 0;
        left: 0;
        height: calc(100vh - 140px) !important;
        overflow-y: auto;
        transform: translateY(100px);
        opacity: 0;
        //
        padding-bottom: 20px;
        pointer-events: none;

        @include respond-above(lg) {
          transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
          box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
        }

        .nav-link {
          text-align: center;
        }

        &.show {
          opacity: 1;
          transform: translateY(0);
          transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
          pointer-events: auto;
        }
      }
    }

    .dropdown-body {
      padding: 1rem 1.25rem;
    }
    .navbar-nav .nav-link {
      position: relative;
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      &:before {
        position: absolute;
        z-index: -1;
        content: "";
        right: 0px;
        top: 5px;
        left: 0px;
        bottom: 5px;
        background-color: rgba(0, 0, 0, 0.03);
        opacity: 1;
        pointer-events: none;
        transform-origin: 50% 100%;
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        border-radius: 0.25rem;
      }
    }
  }

  .navbar {
    .navbar-mobile-search {
      color: $primary-color;
      padding: 0.25rem;
      margin-right: 0.75rem;
      font-size: 1.5rem;
      line-height: 24px;
      border: 0;
      background: transparent;
    }
    .navbar-toggler {
      box-shadow: none;
      outline: 0;
      margin-right: -12px;
      padding: 0.25rem;
      .icon-bar {
        width: 22px;
        height: 2px;
        background-color: $primary-color;
        display: block;
        transition: all 0.2s;
      }
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      .middle-bar {
        opacity: 0;
        margin-top: 4px;
      }
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        margin-top: 4px;
      }
      &.collapsed {
        .top-bar {
          transform: rotate(0);
        }
        .middle-bar {
          opacity: 1;
        }
        .bottom-bar {
          transform: rotate(0);
        }
      }
    }
    .dropdown-menu {
      min-width: 16rem;
      padding: 1.25rem 1.75rem;
      background-color: $white;
      border: 0 solid rgba(31, 45, 61, 0);
      border-radius: 0.5rem;
      box-shadow: 0 1.5rem 4rem rgba(31, 45, 61, 0.15);
    }

    .dropdown-item {
      padding: 0.375rem 0;
      color: $text-color;
      font-size: 1rem;

      &:focus,
      &:hover {
        color: $primary-color;
        text-decoration: none;
        background: 0 0;
      }
    }

    .card {
      border: none;

      .card-body {
        padding-left: 0;
        padding-right: 0;

        p {
          font-size: 0.7rem;
        }
      }

      .card-img-top {
        object-fit: cover;
        height: 200px;
        @include respond-above(md) {
          height: 120px;
        }
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu-xl {
    .dropdown-header {
      padding: 0.375rem 0;
      color: $primary-color;
      font-size: 1rem;
    }
  }
}

.omnisearch {
  $self: &;

  &__no-result,
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  width: 100%;
  margin-top: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.15s;

  .container {
    position: relative;
    height: 100%;
  }

  &-container {
    margin: auto;
    width: 100%;

    @include respond-above(md) {
      width: 800px;
    }
  }

  &-form {
    display: block;
    position: relative;
    z-index: 700;
    background: $white;
    border-radius: 0.5rem;

    .input-group-text {
      font-size: 1.25rem;
      background: 0 0;
      color: $primary-color;
    }

    .form-control {
      display: block;
      height: 58px;
      font-size: 1.25rem;
      color: $primary-color;
      background-color: transparent;
      background-image: none;
    }
  }

  &-suggestions {
    min-height: 150px;
    padding: 1.5rem;
    background: $white;
    border-radius: 0.5rem;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.21s;

    &:before {
      background: $white;
      box-shadow: none;
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      left: 20px;
      position: absolute;
      bottom: 100%;
      transform: rotate(-45deg) translateY(1rem);
      z-index: -5;
      border-radius: 0.2rem;
    }

    .heading {
      color: $gray-500;
    }

    .list-link {
      display: block;
      color: $gray-600;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      span {
        color: $gray-800;
      }

      &:hover {
        text-decoration: none;
        color: $primary-color;
        span {
          color: $primary-color;
        }
      }
    }
  }

  &.show {
    opacity: 1;
    transform: translateY(10%);

    #{$self}-form,
    #{$self}-suggestions {
      pointer-events: auto;
    }

    #{$self}-suggestions {
      opacity: 1;
    }
  }
}

.sa-header--light {
  &:before {
    height: 300px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

.mobilenav-open .sa-header,
.sa-header--light {
  border-color: rgba(255, 255, 255, 0.1);
  color: $white;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.sa-header--closer {
    &:before {
      height: 190px;
      background: linear-gradient(
        180deg,
        $primary-color 0%,
        $primary-color 75%,
        rgba(43, 38, 110, 0) 100%
      );
    }
  }

  .sa-header__top {
    &__inner {
      &:after {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      }
    }
  }

  .sa-header__sa-logo {
    &__light {
      display: block;
    }

    &__dark {
      display: none;
    }
  }

  .sa-header__logo {
    &__light {
      display: block;
    }

    &__dark {
      display: none;
    }
  }

  a {
    color: $white;

    &:hover {
      color: $secondary-color;
    }
  }

  .btn {
    color: $white;
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      color: $primary-color;
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  .sa-social {
    a {
      .sa-icon {
        path {
          fill: $white;
        }
      }
      &:hover {
        .sa-icon {
          path {
            fill: $secondary-color;
          }
        }
      }
    }
  }

  .navbar {
    .navbar-mobile-search {
      color: $white;
    }
    .navbar-toggler {
      .icon-bar {
        background-color: $white;
      }
    }
  }
}

.mobilenav-open {
  .sa-header {
    &:before {
      height: 100vh;
      background: $primary-color;
    }

    &.sa-header--closer {
      &:before {
        height: 100vh;
        background: $primary-color;
      }
    }

    .navbar-nav .nav-link {
      &:before {
        background: none;
      }
    }
  }
}
