.sa-n-masonry {
  $self: &;
  position: relative;

  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-color;
    font-size: 5rem;
  }

  &__no-result-icon {
    font-size: 5rem;
  }

  &__tools {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;

    .btn {
      cursor: pointer;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__item {
    position: relative;

    .card {
      border-radius: 0;
      border: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      border-bottom: 1px solid $gray-300;

      .card-link {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-indent: -9999px;
      }

      .card-img-wrapper {
        position: relative;
        display: block;
        width: 100%;
        overflow: hidden;

        .card-img-top {
          border-radius: 0;
          object-fit: cover;
          height: 240px;
          pointer-events: none;
          transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }

        &:after {
          position: absolute;
          z-index: 3;
          right: 0;
          bottom: 0;
          left: 0;
          height: 5px;
          background: $secondary-color;
          content: "";
          opacity: 0;
          transform: translate(0, 100%);
          transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        }
      }

      .card-body {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;

        p {
          flex-grow: 1;
        }

        .btn {
          align-self: flex-start;
        }

        .card-title {
          margin-top: 10px;
        }
      }

      &:hover {
        .card-img-wrapper {
          .card-img-top {
            transform: scale(1.05);
          }
          &:after {
            opacity: 1;
            transform: translate(0, 0%);
          }
        }
        .btn {
          background-color: $secondary-color;
          border-color: $secondary-color;
        }
      }
    }
  }

  &--list-view {
    #{$self}__item {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .card {
      width: 100%;
      flex-direction: row;
      padding-bottom: 50px;
      justify-content: flex-start;

      .card-img-wrapper {
        position: relative;
        width: 25vw;
        height: 25vw;
        max-width: 200px;
        max-height: 200px;
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;

        .card-img-top {
          width: 100%;
          height: 100%;
        }
      }

      .card-body {
        display: block;
        padding-left: 15px;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 15px;

        .card-title {
          margin-top: 0px;
        }
      }
    }
  }
}

.sa-home-newsMasonry,
.sa-detail-newsMasonry {
  .card {
    border: none;
  }
}
