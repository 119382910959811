body {
  overflow: auto;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  &.mobilenav-open {
    overflow: hidden;
  }

  &.omnisearch-open {
    overflow: hidden;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0 none;
}

form {
  margin: 0;
}

:focus {
  outline: none;
}

::selection {
  background: $primary-color;
  color: $white;
}
::-moz-selection {
  background: $primary-color;
  color: $white;
}

.is-hidden {
  display: none !important;
}

.container {
  @media (min-width: 1440px) {
    max-width: 1280px;
  }
}

.badge-secondary {
  color: $white;
}

.sa-section-title {
  color: $primary-color;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 70px;

  > span {
    font-size: 80%;

    @include respond-above(md) {
      font-size: 100%;
    }
  }

  &:after {
    content: "";
    width: 100px;
    position: absolute;
    margin: 0 auto;
    height: 10%;
    border-radius: 1px;
    background: $secondary-color;
    left: 0;
    right: 0;
    bottom: -1rem;
  }

  &--no-decoration {
    margin: 30px 0 30px;
    &:after {
      display: none;
    }
  }

  &--left {
    text-align: left;
    margin: 30px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      flex-grow: 1;
    }

    &:after {
      width: 100%;
      right: auto;
      height: 1px;
      bottom: -10px;
    }
  }

  .btn {
    text-transform: none;
  }
}

.sa-section-subtitle {
  line-height: 2rem;
  text-align: center;

  @include respond-above(lg) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.bg-primary {
  .sa-section-title {
    color: $white;
  }
}

.mask-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1040;

  &-dark {
    background: rgba(31, 45, 61, 0.3);
  }
}

.input-group-flush .form-control,
.input-group-flush .input-group-text {
  border: 0 !important;
}

.input-group-merge .form-control {
  box-shadow: none;
}

.input-group-merge
  .form-control:not(:first-child):not(.is-valid):not(.is-invalid) {
  border-left: 0;
  padding-left: 0;
}

.sa-scrolling-hero {
  $self: &;

  color: white;
  height: auto;
  min-height: 80vh;
  position: relative;
  background-color: $primary-color;

  &__image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 0%;
    transition: all 0.75s cubic-bezier(0.15, 0.45, 0.25, 1);
    transition-property: opacity, transform;
    will-change: opacity, transform;

    &:before {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      height: 400px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.5) 100%
      );
      content: "";
      transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 0;
    display: flex;
    height: 80vh;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__heading {
    /* margin-right: auto;
    margin-left: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    will-change: transform, opacity; */
    margin-bottom: 10vh;
  }

  &--short {
    min-height: 60vh;

    #{$self}__content {
      height: 60vh;
    }
  }

  &.is-scrolled {
    #{$self}__image {
      opacity: 0.1;
      transform: scale(0.5) translateY(-100px);
    }
  }
}

.sa-changemakers-masonry {
  $self: &;

  &__decoration {
    position: relative;
    margin: 30px auto;

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      margin: 0 auto;
      height: 1px;
      border-radius: 1px;
      background: $gray-200;
      left: 0;
      right: auto;
      bottom: 0;
    }
  }

  &__select-title {
    padding: 10px 0;
    color: $secondary-color;
  }

  &__search {
    display: block;
    position: relative;
    z-index: 700;
    background: $gray-100;
    border-radius: 0.5rem;
    width: 100%;

    .input-group-text {
      .btn {
        font-size: 1.5rem;
        padding: 0;
        margin: 0 0.375rem;
        background: 0 0;
        color: $primary-color;
      }
    }

    .form-control {
      display: block;
      height: 58px;
      font-size: 1.25rem;
      color: $primary-color;
      background-color: transparent;
      background-image: none;
    }

    .btn {
      box-shadow: none;
    }
  }
}

.sa-news-masonry {
  $self: &;

  &__decoration {
    position: relative;
    margin: 30px auto;

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      margin: 0 auto;
      height: 1px;
      border-radius: 1px;
      background: $gray-200;
      left: 0;
      right: auto;
      bottom: 0;
    }
  }

  &__select-title {
    padding: 10px 0;
    color: $secondary-color;
  }

  &__search {
    display: block;
    position: relative;
    z-index: 700;
    background: $gray-100;
    border-radius: 0.5rem;
    width: 100%;

    .input-group-text {
      .btn {
        font-size: 1.5rem;
        padding: 0;
        margin: 0 0.375rem;
        background: 0 0;
        color: $primary-color;
      }
    }

    .form-control {
      display: block;
      height: 58px;
      font-size: 1.25rem;
      color: $primary-color;
      background-color: transparent;
      background-image: none;
    }

    .btn {
      box-shadow: none;
    }
  }
}

.sa-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  path {
    fill: $primary-color;
  }
}

.badge {
  font-weight: 400;

  &.badge-lg {
    font-size: 100%;
  }
}

.btn-primary {
  &:hover {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  &.disabled,
  &:disabled {
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px $gray-500;
  z-index: 1049;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: transform 200ms linear;
  // mix-blend-mode: difference;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: "LineAwesome";
  content: "\f122";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: $gray-500;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: opacity 200ms linear;
}
.progress-wrap:hover::after {
  opacity: 0;
}
.progress-wrap::before {
  position: absolute;
  font-family: "LineAwesome";
  content: "\f122";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background-color: $secondary-color;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: $primary-color;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.sa-breadcrumb {
  .breadcrumb {
    background: none;
    padding-left: 0;
    padding-right: 0;

    .breadcrumb-item {
      &::before {
        color: $gray-400;
      }
      a {
        color: $gray-400;

        &:hover {
          color: $primary-color;
          text-decoration: none;
        }
      }
    }
  }
}

//detail

@keyframes swinging {
  0% {
    transform: rotate(-10deg) translate3d(-15px, 0, 0);
  }
  100% {
    transform: rotate(10deg) translate3d(15px, 0, 0);
  }
}

@keyframes click {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.sa-slick {
  position: relative;

  &__guide-wrapper {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    // background: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    transition: opacity 1s ease;

    @include respond-above(sm) {
      display: flex;
    }

    &.hidden {
      opacity: 0;
    }
  }

  &__guide {
    width: 80px;
    height: 80px;
    //border-radius: 100%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mouse {
      width: 40px;
      height: 40px;
      transform-origin: center bottom;
      animation-name: swinging;
      animation-duration: 1.2s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-delay: 0s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-fill-mode: none;
      animation-play-state: running;

      g {
        fill: $primary-color;
      }
    }

    .mouse-click,
    .mouse-large-click,
    .mouse-small-click {
      opacity: 0;
      transform-origin: center center;
      animation-name: click;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-fill-mode: forward;
      animation-play-state: running;
    }
  }

  .slick-list.draggable {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .slick-slide {
    height: auto;
    &:first-child {
      .sa-slick__item {
        padding-left: 15px;

        @include respond-above(xs) {
          padding-left: 2rem;
        }
      }
    }
  }

  &__item {
    max-width: 80vw;
    position: relative;
    height: 260px;
    padding-right: 2rem;
    float: left;
    overflow: hidden;

    @include respond-above(md) {
      height: 400px;
    }

    @include respond-above(lg) {
      height: 600px;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__video-closer {
    cursor: pointer;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 200ms ease-out;

    &__button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

      svg {
        .stroke-solid {
          stroke-dashoffset: 0;
          stroke-dasharray: 300;
          stroke-width: 4px;
          transition: stroke-dashoffset 1s ease, opacity 1s ease;
        }

        .icon {
          transform: scale(0.8);
          transform-origin: 50% 50%;
          transition: transform 200ms ease-out;
        }
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.3);
      svg {
        .stroke-solid {
          opacity: 1;
          stroke-dashoffset: 300;
        }
        .icon {
          transform: scale(0.9);
        }
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.modal {
  $self: &;

  &--full {
    .modal-dialog {
      min-width: 100%;
      margin: 0;
    }

    .modal-content {
      min-height: 100vh;
      border: 0;
      border-radius: 0;
      background: rgba(30, 30, 30, 0.9);
    }
  }

  &--video {
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background: #000;

      span {
        color: $white;
        text-shadow: none;
      }

      &:hover {
        color: $white;
      }
    }
    .modal-content {
      justify-content: center;
      align-items: center;
    }
    .modal-body {
      padding: 0;
    }
    #{$self}__video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      iframe {
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sa-detail-sidebar,
.sa-news-detail-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  transition: opacity 0.2s, visibility 0.2s;

  @include respond-above(lg) {
    margin-bottom: 0;
    margin-top: -150px;
    margin-left: auto;
    margin-right: 20px;
    max-width: 340px;
  }

  &__container {
    position: relative;
    box-shadow: 0 0 20px 2px hsla(0, 0%, 64%, 0.2);
    background: $white;
    border-radius: 0 0 5px 5px;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;

    .info {
      padding: 20px 30px;
    }

    .tags {
      padding: 20px 30px;
    }

    .other-links {
      list-style: none;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      padding: 0;
      margin: 0;

      li > a {
        display: block;
        padding: 20px 30px;
        background: $white;
        color: $text-color;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        &:hover {
          color: $primary-color;
          text-decoration: none;
        }
      }

      i {
        padding-right: 30px;
      }

      .reveal-social {
        position: relative;
        display: block;
        padding: 20px 30px;
        background: $white;
        color: $text-color;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &:hover {
          background: $gray-100;

          .social-label {
            opacity: 0;
            visibility: hidden;
          }

          .social-links {
            opacity: 1;
            visibility: visible;
          }
        }

        .content {
          display: inline-block;
          position: relative;
        }

        .social-label,
        .social-links {
          transition: opacity 0.2s, visibility 0.2s;
        }

        .social-links {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 50%;
          right: auto;
          transform: translateY(-50%);
          left: 0;

          a {
            display: flex;
            margin: 0 2px;
            transition: color 0.2s ease-in;
            padding: 8px;

            &:hover {
              text-decoration: none;
              .sa-icon {
                path {
                  fill: $secondary-color;
                }
              }
            }
          }

          .sa-icon {
            width: 24px;
            height: 24px;
          }

          .inner {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            margin-left: -6px;
          }
        }
      }
    }
  }
}

//news detail
.sa-news-detail-carousel {
  .carousel {
    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
}

.sa-news-detail-sidebar {
  @include respond-above(lg) {
    margin-top: 0px;
  }
}

.char-counter {
  background-color: $gray-200;
  color: $gray-500;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  font-size: 70%;
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

//application form
.sa-application-form {
  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.01);
  }

  .form-result--icon {
    font-size: 5rem;
  }

  .sa-select {
    .select2-selection {
      border-radius: 4px;
    }
    .select2-container--open {
      &.select2-container--above {
        .select2-selection {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
      &.select2-container--below {
        .select2-selection {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  .bs-stepper-content {
    position: relative;
  }
  .bs-stepper-header button {
    white-space: normal;
  }
  .bs-stepper-circle {
    background-color: $gray-500;
  }
  .bs-stepper-label {
    color: $gray-500;
    display: none;
    @include respond-above(sm) {
      display: inline-block;
    }
  }

  .active {
    .bs-stepper-circle {
      background-color: $primary-color;
    }
    .bs-stepper-label {
      color: $primary-color;
    }
  }
}

.sa-faq {
  font-size: 1.25rem;
  .accordion {
    font-size: 80%;

    @include respond-above(md) {
      font-size: 100%;
    }
  }

  .btn {
    font-size: 100%;
  }
}

.sa-contact {
  &__decoration {
    width: 20rem;
    border-bottom: 1px solid $gray-300;
    margin: 3rem auto;
  }

  &__info {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.25rem;

    li {
      padding-bottom: 1rem;

      span {
        color: $primary-color;
      }

      a:hover {
        color: $secondary-color;
        text-decoration: none;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

//contact form
.sa-contact-form {
  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.01);
  }

  .form-result--icon {
    font-size: 5rem;
  }
}
