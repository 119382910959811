.sa-home {
  $self: &;

  position: relative;
  min-height: 100vh;
}

.sa-home-hero {
  $self: &;

  //color: $white;
  // color: $primary-color;
  height: auto;
  min-height: 100vh;
  position: relative;
  //background-color: $primary-color;
  background-color: $white;
  // background-color: rgb(255, 252, 247);

  &__inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: relative;
  }

  &__scroll {
    position: absolute;
    width: 30px;
    height: 60px;
    left: 50%;
    bottom: 2%;
    margin-left: -15px;
    margin-top: -30px;
    box-shadow: inset 0 0 0 1px $gray-300;
    border-radius: 20px;

    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background: $gray-500;
      left: 50%;
      top: 8px;
      margin-left: -3px;
      border-radius: 3px;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-name: scroll;
      opacity: 0.5;
    }
  }

  &__heading {
    top: 50%;
    width: 50%;
    z-index: 5;
    left: 33.33%;
    position: absolute;
    transform: translateY(-50%);
    pointer-events: none;

    @include respond-below(xs) {
      transform: translateY(70px);
      width: 80%;
      left: 10%;
    }

    h2 {
      width: 80%;
      font-size: 6vw;
      opacity: 1;
      line-height: 6vw;
      color: $primary-color;

      @include respond-below(lg) {
        width: 70%;
        font-size: 5.5vw;
        line-height: 5.5vw;
      }

      @include respond-below(xs) {
        width: 100%;
        text-align: center;
      }
    }

    p {
      opacity: 1;
      margin-top: 5vw;
      margin-left: 0.83vw;
      width: 60%;
      font-size: 1vw;

      @include respond-below(lg) {
        margin-top: 6vw;
      }

      @include respond-below(md) {
        font-size: 1.4vw;
        margin-top: 7vw;
      }

      @include respond-below(sm) {
        width: 65%;
        font-size: 1.7vw;
        margin-top: 10vw;
      }

      @include respond-below(xs) {
        width: 100%;
        text-align: center;
        font-size: 3vw;
        margin-top: 15vw;
      }
    }
  }

  /* &__shape {
    top: 41vh;
    left: 28vw;
    width: 26%;
    z-index: 4;
    opacity: 0.2;
    position: absolute;
    border-radius: 50%;
    padding-bottom: 26%;
    border: 2px solid white;
    transform: translateY(-50%);
    pointer-events: none;
    display: none;
  } */

  &__shape {
    top: 65vh;
    right: 35vw;
    width: 15%;
    z-index: 1;
    position: absolute;
    padding-bottom: 26%;
    pointer-events: none;
    //opacity: 0.1;

    @include respond-below(xs) {
      top: 78vh;
      right: 15vw;
      width: 20%;
    }

    img {
      width: 100%;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));
    }
  }

  &__images {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__left,
  &__right {
    position: relative;
  }

  &__image {
    display: block;
    position: absolute;
    will-change: transform;
    transform: translateY(-50%);
    backface-visibility: hidden;

    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      object-position: 50% 50%;
      backface-visibility: hidden;
    }
  }

  &__left {
    #{$self}__image {
      &:nth-child(1) {
        width: 23.5%;
        padding-bottom: 33%;
        top: 31vh;
        left: 11vw;
        z-index: 1;

        @include respond-below(xs) {
          top: 25vh;
          left: 30vw;
          width: 41vw;
          padding-bottom: 50%;
        }

        &:after {
          pointer-events: none;
          content: "";
          /*background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.7) 100%
          ); */

          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0.7) 100%
          );
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      &:nth-child(2) {
        width: 14.5%;
        padding-bottom: 22%;
        top: 50vh;
        left: 2vw;
        z-index: 2;

        @include respond-below(xs) {
          left: 10vw;
          width: 30vw;
          padding-bottom: 40%;
          top: 40vh;
        }
      }

      &:nth-child(3) {
        width: 12%;
        padding-bottom: 16%;
        top: 70vh;
        z-index: 3;
        left: 10vw;

        @include respond-below(xs) {
          display: none;
        }
      }
    }
  }

  &__right {
    #{$self}__image {
      &:nth-child(1) {
        width: 16.66%;
        padding-bottom: 22%;
        top: 24vh;
        z-index: 1;
        right: 24vw;

        @include respond-below(xs) {
          display: none;
        }

        @include respond-below(md) {
          //top: 25vh;
          //right: 18vw;
        }

        &:after {
          pointer-events: none;
          content: "";
          /*background: linear-gradient(
            to left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.7) 100%
          ); */

          background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0.4) 100%
          );
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      &:nth-child(2) {
        width: 14.5%;
        padding-bottom: 22%;
        top: 31vh;
        z-index: 3;
        right: 18vw;

        @include respond-below(md) {
          //right: 10vw;
        }

        @include respond-below(xs) {
          top: 35vh;
          right: 20vw;
          width: 30vw;
          padding-bottom: 40%;
        }
      }

      &:nth-child(3) {
        width: 12%;
        padding-bottom: 16%;
        top: 55vh;
        z-index: 2;
        right: 23vw;

        @include respond-below(xs) {
          display: none;
        }

        @include respond-below(md) {
          //right: 18vw;
          //top: 60vh;
        }
      }
    }
  }

  &__intro {
    position: relative;
    padding-top: 8vw;
    padding-bottom: 7rem;

    .card {
      h2 {
        color: $secondary-color;
        font-size: 4.5rem;
        text-shadow: longshadow(darken($white, 10%), $white, 80, 0.8);
      }

      p {
        color: $primary-color;
        font-size: 1.2rem;
      }
    }
  }

  &__decoration {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background: $secondary-color;
  }
}

.sa-home-themes {
  position: relative;

  .card {
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
      text-decoration: none;
    }
  }
}

.sa-home-benefits {
  position: relative;

  #testimonials {
    margin-bottom: 10rem;
  }

  .carousel-inner {
    padding-top: 5rem;
  }

  .carousel-quote {
    pointer-events: none;
    position: absolute;
    width: 8rem;
    height: 8rem;
    left: 50%;
    top: 10%;
    margin-left: -4rem;

    &:after {
      position: absolute;
      content: "\93";
      color: lighten($primary-color, 70%);
      font-size: 20rem;
      line-height: 15rem;
      left: -1.75rem;
      top: 0;
    }
  }

  .carousel-item {
    min-height: 300px;

    img {
      width: 75px;
      height: 75px;
      display: block;
      border-radius: 50%;
    }
  }

  .carousel .carousel-indicators {
    bottom: -70px;
  }
  .carousel-indicators li,
  .carousel-indicators li.active {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 1px 2px;
  }
  .carousel-indicators li {
    background: $gray-400;
    border: 5px solid $white;
    &.active {
      color: $white;
      background: $primary-color;
      border: 5px double;
    }
  }

  .card {
    margin-bottom: 30px;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.5rem !important;
  }

  &.bg-primary {
    .carousel-quote {
      &:after {
        color: darken($primary-color, 5%);
      }
    }
    .carousel-item {
      color: $white;
    }

    .carousel-indicators li {
      background: darken($primary-color, 8%);
      border: 5px solid $primary-color;
      &.active {
        color: $primary-color;
        background: $white;
        border: 5px double;
      }
    }
  }
}

@keyframes scroll {
  20%,
  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}
