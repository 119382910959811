.sa-newsletter {
  $self: &;
  position: relative;

  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.01);
    z-index: 10;
  }

  &__success,
  &__error,
  &__field-error {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px 20px;
    background-color: $primary-color;
    color: $white;
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;

    @include respond-above(md) {
      flex-direction: row;
      height: 215px;
      padding: 30px;
      color: $white;

      &:before {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        content: "";
      }
    }

    @include respond-above(lg) {
      height: 270px;
      padding: 40px;
    }
  }

  &__image {
    height: 200px;
    @include respond-above(md) {
      height: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include respond-above(md) {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    color: $primary-color;

    @include respond-above(md) {
      flex: 1;
      padding-right: 70px;
      padding-left: 0px;
      color: $white;
    }

    h4 {
      font-size: 1rem;

      @include respond-above(md) {
        font-size: 1.5rem;
      }
    }
  }

  &__form {
    z-index: 2;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10px;

    @include respond-above(md) {
      padding-top: 0px;
    }

    &__inner {
      display: block;
      position: relative;
      z-index: 700;
      background: $gray-100;
      border-radius: 0.5rem;
      width: 100%;

      @include respond-above(md) {
        background: $white;
        width: auto;
      }

      .input-group-text {
        font-size: 1.25rem;
        background: 0 0;
        color: $primary-color;
      }

      .form-control {
        display: block;
        height: 58px;
        font-size: 1.25rem;
        color: $primary-color;
        background-color: transparent;
        background-image: none;
      }

      .btn {
        box-shadow: none;
      }
    }

    @include respond-above(md) {
      justify-content: flex-end;
      max-width: 420px;
    }

    @include respond-above(lg) {
      max-width: 510px;
    }
  }
}
