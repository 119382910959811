.sa-main {
  position: relative;
  z-index: 0;
  min-height: 500px;

  &--padding {
    padding-top: 142px;

    @include respond-above(sm) {
      padding-top: 152px;
    }

    @include respond-above(lg) {
      padding-top: 191px;
    }
  }
}
