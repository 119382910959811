.sa-footer {
  $self: &;
  position: relative;
  margin-top: 50px;
  padding-bottom: 10px;

  &__top {
    font-size: 0.9rem;
    color: $white;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-above(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    #{$self}__menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.5rem;
      text-align: center;
      @include respond-above(md) {
        flex-direction: row;
        margin-bottom: 0;
        text-align: left;
      }

      a {
        color: $white;
        margin: 0.5rem 0.5rem 0;

        &:hover {
          color: $secondary-color;
          text-decoration: none;
        }

        @include respond-above(md) {
          margin-top: 0;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    #{$self}__social {
      a {
        color: $white;
        margin: 0 0.5rem;

        @include respond-above(md) {
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .sa-icon {
          width: 24px;
          height: 24px;
          path {
            fill: $white;
          }
        }
        &:hover {
          .sa-icon {
            path {
              fill: $secondary-color;
            }
          }
        }
      }
    }
  }

  &__middle {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: $white;
    margin: 0.75rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }

    img {
      float: left;
      margin-top: 0.1rem;
      height: 1rem;
    }

    @include respond-above(md) {
      height: 1.2rem;
      text-align: left;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      a {
        margin: 0;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
      }
    }
  }

  &__bottom {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.2);
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-above(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    > div {
      margin: 0.5rem 0;

      @include respond-above(md) {
        margin: 0;
      }

      a {
        color: rgba(255, 255, 255, 0.2);
        margin: 0 0.5rem;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: $secondary-color;
          text-decoration: none;
        }
      }
    }
  }

  &__decoration {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    height: 10px;
    background: $secondary-color;

    &--thin {
      height: 1px;
      background: darken($primary-color, 5%);
    }
  }
}
