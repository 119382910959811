@import "colors";
@import "fonts";

$body-bg: $bg-color;
$body-color: $text-color;

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color
);

$font-family-sans-serif: $primary-font, -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 16;

$image-path: "../media";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-transitions";

@function longshadow($color_a, $color_b, $stepnum, $opacity: 1) {
  $gradient_steps: null;
  @for $i from 1 through $stepnum {
    $weight: (($i - 1) / $stepnum) * 100;
    $colour_mix: mix($color_b, rgba($color_a, $opacity), $weight);
    $seperator: null;
    @if ($i != $stepnum) {
      $seperator: #{","};
    }
    $gradient_steps: append(
      #{$gradient_steps},
      #{$i}px #{$i}px $colour_mix $seperator
    );
  }
  @return $gradient_steps;
}

@import "media-queries";
@import "general";
@import "header";
@import "footer";
@import "main";
@import "home";
@import "cm-masonry";
@import "n-masonry";
@import "newsletter";
@import "select2";
@import "tagsinput";
